var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"400"},model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Cargando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_c('div',[_c('v-card',{staticClass:"py-3 mb-4 px-4"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('h3',{staticClass:"my-4"},[_vm._v("Datos del Dueño")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Correo eletrónico","required":"","placeholder":"Correo eletrónico del propietario","outlined":""},model:{value:(_vm.postData.email),callback:function ($$v) {_vm.$set(_vm.postData, "email", $$v)},expression:"postData.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Nombre","required":"","placeholder":"Nombre del propietario","outlined":""},model:{value:(_vm.postData.name),callback:function ($$v) {_vm.$set(_vm.postData, "name", $$v)},expression:"postData.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Cellphone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Teléfono / Celular","required":"","placeholder":"Número de teléfono o celular del propietario","outlined":""},model:{value:(_vm.postData.cellphone),callback:function ($$v) {_vm.$set(_vm.postData, "cellphone", $$v)},expression:"postData.cellphone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"BirthDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de nacimiento","readonly":"","placeholder":"YYYY/MM/DD","outlined":""},model:{value:(_vm.postData.birthDate),callback:function ($$v) {_vm.$set(_vm.postData, "birthDate", $$v)},expression:"postData.birthDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01"},on:{"change":_vm.save},model:{value:(_vm.postData.birthDate),callback:function ($$v) {_vm.$set(_vm.postData, "birthDate", $$v)},expression:"postData.birthDate"}})],1)]}}],null,true)}),(!_vm.passBtn)?_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'fas fa-eye' : 'fas fa-eye-slash',"error-messages":errors,"type":_vm.show1 ? 'text' : 'password',"label":"Contraseña","required":"","placeholder":"Contraseña","outlined":"","counter":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}):_vm._e(),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:(['EDIT']),expression:"['EDIT']"}],staticClass:"mr-4",attrs:{"color":"accent","type":"submit","loading":_vm.loading,"disabled":invalid}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"disabled":_vm.disabled,"color":"error"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.passBtn)?_c('ZbPasswordComponent',{attrs:{"user":_vm.userData}}):_vm._e()],1)],1)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }