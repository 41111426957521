import ZbPasswordComponent from '../password-modal'
import { ServiceFactory } from '@/services/ServiceFactory'
const _modulesService = ServiceFactory.get('ModulesService')
const _barMembersService = ServiceFactory.get('BarMembersService')
export default {
  name: 'ZbBarTeamFormComponent',
  components: {
    ZbPasswordComponent
  },
  data () {
    return {
      show1: false,
      password: 'Password',
      postData: {
        name: '',
        username: '',
        email: '',
        cellphone: 0,
        birthDate: ''
      },
      cities: [
        {
          name: 'La Paz'
        },
        {
          name: 'Cochabamba'
        },
        {
          name: 'Santa Cruz'
        },
        {
          name: 'Oruro'
        },
        {
          name: 'Potosi'
        },
        {
          name: 'Beni'
        },
        {
          name: 'Pando'
        },
        {
          name: 'Tarija'
        },
        {
          name: 'Chuquisaca'
        }
      ],
      genres: [
        {
          name: 'Rock',
          value: 'rock'
        },
        {
          name: 'Electro',
          value: 'electro'
        },
        {
          name: 'Jazz',
          value: 'jazz'
        },
        {
          name: 'Electro House',
          value: 'electro house'
        },
        {
          name: 'Hip Hop',
          value: 'hip hop'
        },
        {
          name: 'Pop',
          value: 'pop'
        },
        {
          name: 'Reggaeton',
          value: 'reggaeton'
        },
        {
          name: 'Cumbia',
          value: 'cumbia'
        }
      ],
      billingPlan: [],
      businessType: [],
      modules: [],
      menu: false,
      menu2: false,
      loading: false,
      disabled: false,
      memberProfile: [],
      postNewMember: {
        name: '',
        email: '',
        username: '',
        birthDate: '',
        password: 'defaultPass',
        modules: [
          {
            zbModuleId: 'fddce52c-3d48-4d8c-b198-ee8b92ab8811',
            active: true,
            permissions: []
          }
        ]
      },
      loader: false,
      passBtn: false,
      userData: {
        id: '',
        name: ''
      }
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    menu2 (val) {
      val && setTimeout(() => (this.$refs.picker2.activePicker = 'YEAR'))
    }
  },
  mounted () {
    // this.indexBillingPlan()
    // this.indexBusinessType()
    this.indexModules()
    this.initialize()
  },
  methods: {
    async initialize () {
      // console.log(this.$route.params)
      if (this.$route.params.type === 'editMember' && this.$route.params.userId) {
        // console.log(this.$route.params.id)
        this.loader = true
        this.passBtn = true
        try {
          this.memberProfile = await _barMembersService.show(this.$route.params.userId)
          console.log(this.memberProfile)
          this.userData.id = this.$route.params.userId
          this.userData.name = this.memberProfile.member.name
          this.postData.name = this.memberProfile.member.name
          this.postData.email = this.memberProfile.member.email
          this.postData.cellphone = this.memberProfile.member.cellphone
          this.postData.birthDate = this.memberProfile.member.birthDate
          this.loader = false
        } catch (error) {
          // console.log(error)
          this.$notify({
            type: 'error',
            text: 'Ocurrió un error al obtener los datos del usuario, vuelva a la tabla de negocios e inténtelo nuevamente'
          })
          this.loader = false
        }
      } else {
        // console.log('Sin parametros de usuario')
      }
      if (this.$route.params.type === 'addMember' && this.$route.params.id) {
        // console.log(this.$route.params.id, this.$route.params.type)
        this.passBtn = false
        try {
          // this.barData = await _barsService.show(this.$route.params.id)
          // this.postData.bar = this.barData.bar
          // this.postData.bar.name = this.barData.bar.name
          // this.postData.bar.address = this.barData.bar.address
          // this.postData.bar.slogan = this.barData.bar.slogan
          // this.postData.bar.activatedAt = this.barData.bar.activatedAt // quitar la hora o modificar el formulario para que acepte la hora
          // this.postData.bar.city = this.barData.bar.city
          // this.postData.bar.musicTicketPrice = this.barData.bar.musicTicketPrice // no existe el campo musicTicketPrice
          // this.postData.bar.genres = this.barData.bar.genres
          // this.postData.bar.zbBillingPlanId = this.barData.bar.zbBillingPlanId // no existe el campo zbBillingPlanId
          // this.postData.bar.zbBusinessTypeId = this.barData.bar.zbBusinessTypeId // el formulario guarda la id, el servicio retorna una cadena de texto
          // this.postData.bar.zbModuleIds = this.barData.bar.zbModuleIds // el sercivio devuelce el valor de los modulos de otra forma
          // this.loadingForm = false
          // console.log(this.barData)
        } catch (error) {
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
      }
    },
    async indexModules () {
      try {
        this.modules = await _modulesService.index()
        // console.log(this.modules.modules[0])
      } catch (error) {
        // console.info(error)
      }
    },
    async submitData () {
      this.loading = true
      this.disabled = true
      this.postData.cellphone = parseInt(this.postData.cellphone)
      // this.postData.bar.musicTicketPrice = parseInt(this.postData.bar.musicTicketPrice)
      // console.log(this.postData)
      switch (this.$route.params.type) {
        case 'addMember':
          this.postNewMember.name = this.postData.name
          this.postNewMember.username = this.postData.name
          this.postNewMember.email = this.postData.email
          this.postNewMember.birthDate = this.postData.birthDate
          this.postNewMember.cellphone = this.postData.cellphone
          // console.log('ADD', this.postNewMember, this.$route.params.id)
          try {
            const res = await _barMembersService.create(this.$route.params.id, this.postNewMember)
            this.loading = false
            this.disabled = false
            // console.log(res)
            this.$notify({
              type: 'success',
              text: 'Usuario agregado correctamente'
            })
            this.close()
          } catch (error) {
            // console.log(error)
            this.$notify({
              type: 'error',
              text: error.msg
            })
            this.loading = false
            this.disabled = false
          }
          break

        case 'editMember':
          // console.log('EDIT', this.postData)
          try {
            const res = await _barMembersService.edit(this.$route.params.userId, this.postData)
            this.loading = false
            this.disabled = false
            this.$notify({
              type: 'success',
              text: 'Datos editados correctamente'
            })
            this.close()
            // console.log(res)
          } catch (error) {
            // console.log(error)
            this.loading = false
            this.disabled = false
            this.$notify({
              type: 'error',
              text: 'Ocurrió un error al guardar los datos, intente nuevamente'
            })
          }
          break

        default:
          break
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
    close () {
      const id = this.$route.params.id
      // console.log(id)
      this.$router.push({ name: 'edit', params: { id } })
    }
    // async indexModules (){}
  }
}
